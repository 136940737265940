<template>
    <div class="download-wraper">
		<iframe name='myFrame' style="display:none;"></iframe>

        <div class="navigate-body">
            <div class="logo-wraper">
            	<el-image @click="openHome" class="logo" :src="require('../assets/images/logo1.png')"/>
            </div>
            <div class="navigate-btns">
                <div class="nav-btn" @click="openHomePage">首页</div>
                <div class="nav-btn" @click="openPcPage">电脑端学习</div>
                <div class="nav-btn active-nav-btn">应用下载</div>
                <div class="nav-btn" @click="openAboutUs">关于我们</div>
            </div>
        </div>

        <div class="download-body">
            <div class="dld-wraper">
                <div class="nav-list">
                    <div :class="{'nav-item': true, 'nav-item-active': active === 1}" @click="switchBtn(1)">手机端</div>
                    <div :class="{'nav-item': true, 'nav-item-active': active === 2}" @click="switchBtn(2)">PAD端</div>
                    <div :class="{'nav-item': true, 'nav-item-active': active === 3}" @click="switchBtn(3)">电视端</div>
                    <div :class="{'nav-item': true, 'nav-item-active': active === 4}" @click="switchBtn(4)">电脑端</div>
                </div>

                <!-- 手机端 -->
                <div class="app-wraper" v-show="active === 1">
                    <div class="app-left animate__animated animate__fadeInLeft">
                        <p class="title">淘知学堂手机客户端</p>
                        <div class="app-left-code">
                            <el-image
                                    :src="require('../assets/images/phoneCode.png')"
                                    fit="cover"
                            />
                        </div>
                        <p class="message">扫码下载</p>
                    </div>
                    <div class="app-right app-right-phone animate__animated animate__fadeInRight">
                        <el-image
                                :src="require('../assets/images/phoneRight.png')"
                                fit="cover"
                        />
                    </div>
                </div>

                <!-- PAD端 -->
                <div class="app-wraper" v-show="active === 2">
                    <div class="app-left animate__animated animate__fadeInLeft">
                        <p class="title">淘知学堂PAD客户端</p>
                        <div class="app-left-code">
                            <el-image
                                    :src="require('../assets/images/padCode.png')"
                                    fit="cover"
                            />
                        </div>
                        <div class="app-left-btn app-left-btn-with-icon" @click="downPadApp">
                          <img style="width: 30px;" src="../assets/images/download/ico-pad.png"/>
                          <span>下载PAD版</span>
                        </div>
                    </div>
                    <div class="app-right app-right-pad animate__animated animate__fadeInRight">
                        <el-image
                                :src="require('../assets/images/padRight.png')"
                                fit="cover"
                        />
                    </div>
                </div>

                <!-- TV端 -->
                <div class="app-wraper" v-show="active === 3">
                    <div class="app-left animate__animated animate__fadeInLeft">
                        <p class="title">淘知学堂电视客户端</p>
                        <div class="app-left-btn" @click="downTvApp">立即下载</div>
                        <div class="app-left-btn" @click="openDownTvApp">应用商店下载</div>
                    </div>
                    <div class="app-right app-right-tv animate__animated animate__fadeInRight">
                        <el-image
                                :src="require('../assets/images/tvRight.png')"
                                fit="cover"
                        />
                    </div>
                </div>

                <!-- 电脑端 -->
                <div class="app-wraper" v-show="active === 4">
                    <div class="app-left animate__animated animate__fadeInLeft">
                        <p class="title">淘知学堂电脑端</p>
                        <div class="app-left-btn app-left-btn-with-icon" @click="downDesktopWinApp">
                          <img style="width: 30px;" src="../assets/images/download/ico-desktop-win.png"/>
                          <span>windows版下载</span>
                        </div>
<!--                        <div class="app-left-btn app-left-btn-with-icon" @click="openDesktopMacApp">-->
<!--                          <img style="width: 30px;" src="../assets/images/download/ico-desktop-mac.png"/>-->
<!--                          <span>mac版下载</span>-->
<!--                        </div>-->
                    </div>
                    <div class="app-right app-right-tv animate__animated animate__fadeInRight">
                        <el-image
                                :src="require('../assets/images/download/desktopRight.png')"
                                fit="cover"
                        />
                    </div>
                </div>
            </div>

            <div class="tv-use" v-if="active === 3">
                <div :class="{'image-list': true, 'animate__animated': true, 'animate__fadeInUp': tvAnimate_a}">
                    <img class="image-item-a" src="../assets/images/img-item-c.png" alt="">
                    <img class="image-item" src="../assets/images/img-item.png" alt="">
                    <img class="image-item-a" src="../assets/images/img-item-a.png" alt="">
                    <img class="image-item" src="../assets/images/img-item.png" alt="">
                    <img class="image-item-a" src="../assets/images/img-item-b.png" alt="">
                </div>
                <div :class="{'tv-list': true, 'animate__animated': true, 'animate__fadeInUp': tvAnimate_b}">
                    <div class="tv-item">
                        <div class="title">下载电视端安装包</div>
                        <div class="msg">点击左侧立即下载按钮，文件开始下载。下载完成后，将其拷贝至U盘中。</div>
                    </div>
                    <div class="tv-item">
                        <div class="title">将U盘链接电视</div>
                        <div class="msg">将U盘插入电视的USB接口，电视将会自动跳出移动存储界面，或者您也可以进入电视的资源文件夹找到U盘。</div>
                    </div>
                    <div class="tv-item">
                        <div class="title">打开U盘完成安装</div>
                        <div class="msg">打开U盘，点击“淘知学堂”apk，完成安装。</div>
                    </div>
                </div>
            </div>
        </div>

        <div :class="{'download-footer': true, 'download-footer-tv': active === 3}">
            <img :class="{'dld-left-a': true, 'dld-left-a-show': dldIcon}" src="../assets/images/dld-left-a.png" alt="">
            <img :class="{'dld-right-a': true, 'dld-right-a-show': dldIcon}" src="../assets/images/dld-right-a.png" alt="">
            <img :class="{'dld-right-b': true, 'dld-right-b-show': dldIcon}" src="../assets/images/dld-right-b.png" alt="">
            <div :class="{'foot-wrap': true, 'animate__animated': true, 'animate__fadeInUp': tvAnimate_b}">
                <div class="foot-video-wrap">
                    <video
                            v-if="playVideoOpen"
                            class="foot-video"
                            :src="videoList[playVideoIndex].video"
                            autoplay
                            controls
                            loop
                            width="100%">
                        您的浏览器不支持 video 标签。
                    </video>

                    <el-image v-if="!playVideoOpen"
                              class="foot-video-cover"
                              :src="videoList[playVideoIndex].cover"
                              fit="cover"/>

                    <img v-if="!playVideoOpen"
                         class="foot-play"
                         src="../assets/images/foot-play.png"
                         @click="videoPlay"
                         alt="">
                </div>
                <div class="foot-right">
                    <div
                            :class="{'foot-right-item': true, 'foot-right-item-active': index === playVideoIndex}"
                            v-for="(item, index) in videoList"
                            :key="index"
                            @click="videoClick(index)"
                    >
                        {{ item.title }}
                    </div>
                </div>
            </div>
        </div>

        <copyright-notice/>


    </div>
</template>

<script>
    import CopyrightNotice from '../components/Layout/CopyrightNotice.vue'

    export default {
        name: "Download",
        components: { CopyrightNotice },
        data() {
            return {
                active: 1,
                playVideoIndex: 0,
                playVideoOpen: false,   // 是否正在播放
                videoList: [
                    {
                        title: '#1 01-账号登录及年级设置',
                        cover: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/cover/1.png',
                        video: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/video/1.mp4'
                    },
                    {
                        title: '#2 02-切换年级',
                        cover: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/cover/2.png',
                        video: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/video/2.mp4'
                    },
                    {
                        title: '#3 03-查看课程',
                        cover: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/cover/3.png',
                        video: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/video/3.mp4'
                    },
                    {
                        title: '#4 04-VIP办理及查看',
                        cover: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/cover/4.png',
                        video: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/video/4.mp4'
                    },
                    {
                        title: '#5 05-教案下载及查看',
                        cover: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/cover/5.png',
                        video: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/video/5.mp4'
                    },
                    {
                        title: '#6 06-点学书使用指南',
                        cover: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/cover/6.png',
                        video: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/video/6.mp4'
                    },
                    {
                        title: '#7 07-在书架添加图书',
                        cover: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/cover/7.png',
                        video: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/video/7.mp4'
                    },
                    {
                        title: '#8 08-帮助中心的使用',
                        cover: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/cover/8.png',
                        video: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/video/8.mp4'
                    },
                    {
                        title: '#9 09-下载资料的方式',
                        cover: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/cover/9.png',
                        video: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/video/9.mp4'
                    },
                    {
                        title: '#10 10-视频课程的倍速播放设置',
                        cover: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/cover/10.png',
                        video: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/video/10.mp4'
                    },
                    {
                        title: '#11 11-查看已购课程',
                        cover: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/cover/11.png',
                        video: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/video/11.mp4'
                    },
                    {
                        title: '#12 12-学习中心的使用',
                        cover: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/cover/12.png',
                        video: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/video/12.mp4'
                    },
                    {
                        title: '#13 13-点学书使用说明',
                        cover: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/cover/13.png',
                        video: 'https://open.oss.taozhi.online/production/assets/taozhi/pc_v2/video/13.mp4'
                    }
                ],
                tvAnimate_a: false,
                tvAnimate_b: false,
                dldIcon: false
            }
        },
        mounted() {
			window.document.title = '淘知学堂 - 应用下载'
			window.scrollTo(0, 0);
            window.onscroll = () => {
                // 变量t是滚动条滚动时，距离顶部的距离
                let t = document.documentElement.scrollTop || document.body.scrollTop;
                if(t > 200) {
                    this.tvAnimate_a = true;
                } else {
                    this.tvAnimate_a = false;
                }

                if(t > 400) {
                    this.tvAnimate_b = true;
                } else {
                    this.tvAnimate_b = false;
                }

                if(t > 600) {
                    this.dldIcon = true;
                } else {
                    this.dldIcon = false;
                }
            }
        },
        methods: {
            openPcPage() {
              window.open('https://www.taozhi.cn/pcweb/')
            },
            /**
             * 跳转到首页
             */
            openHomePage() {
                this.$router.push({
                    path: '/'
                });
            },
            /**
             * 跳转到关于我们
             */
            openAboutUs() {
                this.$router.push({
                    path: '/aboutus'
                })
            },
            /**
             * tab切换
             * @param val
             */
            switchBtn(val) {
                this.active = val
            },
            /**
             * 视频播放列表点击
             * @param index
             */
            videoClick(index) {
                this.playVideoIndex = index;
                this.playVideoOpen = false;
            },
            videoPlay() {
                this.playVideoOpen = true;
            },
			/**
			 * 下载Tv版 APP
			 */
			downTvApp() {
				window.open('https://open.oss.taozhi.online/production/version/sysdef202007021429tpb7fb0g/Android/v1.0.8.apk', 'myFrame');
			},
			openDownTvApp() {
				window.open('http://down.7po.com/apps/1/taozhi.html')
			},
      downPadApp() {
				window.open('https://t.taozhi.cn/iamm2a')
			},
      downDesktopWinApp() {
				window.open('https://t.taozhi.cn/RjUR32')
			},
			openHome() {
				this.$router.push({
					path: '/'
				});
			},
        }
    }
</script>

<style scoped lang="scss">
    .download-wraper {

    }

    .navigate-body {
        width: 100%;
        min-width: 1200px;
        height: 80px;
        background-color: #fff;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9;
        padding: 0 120px;
        box-sizing: border-box;
        -webkit-box-shadow: 0px 8px 6px 5px #cbecff;
        -moz-box-shadow: 0px 8px 6px 5px #cbecff;
        box-shadow: 0px 8px 6px 0px rgba(203, 236, 255, 0.2);
        display: flex;
        justify-content: space-between;

        .logo-wraper {
        	width: 176px;
        	height: 113px;
        	overflow: hidden;
        }
        .logo {
            width: 185px;
            cursor: pointer;
            fliter: drop-shadow(0px 1px 1px #edf8ff);
            -webkit-filter: drop-shadow(0px 1px 1px #edf8ff);
        }

        .navigate-btns {
            display: flex;
            height: 80px;
            padding: 15px 0;

            .nav-btn {
                height: 50px;
                line-height: 50px;
                margin: 0 20px;
                font-size: 18px;
                color: #2A536B;
                cursor: pointer;
            	background-image: url("../assets/images/nav-icon.png");
            	background-repeat: no-repeat;
            	background-size: 24px 4px;
            	background-position: center 60px;
            	transition: all .3s;
            }

            .nav-btn:hover {
                color: #00A2FF;
                background-position: center 46px;
            }

            .active-nav-btn {
                color: #00A2FF;
                background-image: url("../assets/images/nav-icon.png");
                background-repeat: no-repeat;
                background-size: 24px 4px;
                background-position: center 46px;
            }
        }
    }

    .download-body {
        width: 100%;
        height: 1090px;
        background-image: url(../assets/images/dld-bac.png);
        background-size: 1920px 1090px;
        background-position: center top;
        background-repeat: no-repeat;
        margin-top: 80px;

        .dld-wraper {
            width: 1200px;
            height: 900px;
            margin: 0 auto;
            overflow: hidden;
        }

        .nav-list {
            width: 430px;
            display: flex;
            justify-content: space-between;
            padding-top: 100px;
            cursor: pointer;

            .nav-item {
                color: #FFFFFF;
                opacity: 0.6;
                font-size: 20px;
                height: 60px;
                line-height: 60px;
                width: 100px;
                text-align: center;
                transition: all .3s;
                background-image: url("../assets/images/dld-nav-active.png");
                -webkit-background-size: 58px 6px;
                background-size: 58px 6px;
                background-repeat: no-repeat;
                background-position: center 80px;
            }

            .nav-item:hover {
                font-size: 24px;
                opacity: 1;
                background-position: center bottom;
            }

            .nav-item-active {
                font-size: 24px;
                opacity: 1;
                background-position: center bottom;
            }
        }
    }

    .app-wraper {
        display: flex;
        padding-top: 40px;

        .app-left {
            width: 520px;

            .title {
                font-size: 48px;
                color: #FFFFFF;
                padding: 80px 0 40px 0;
                width: 100%;
                text-align: center;
            }

            .message {
                text-align: center;
                font-size: 18px;
                color: #ffffff;
                line-height: 32px;
                padding-top: 10px;
            }

            .app-left-code {
                width: 300px;
                height: 300px;
                background-color: #fff;
                border-radius: 20px;
                margin: 0 auto;
                el-image {
                    border-radius: 20px;
                }
            }

            .app-left-btn {
                width: 258px;
                height: 60px;
                background-color: #fff;
                border-radius: 30px;
                color: #00A2FF;
                font-size: 28px;
                line-height: 60px;
                text-align: center;
                cursor: pointer;
                margin: 40px auto 0;

                &.app-left-btn-with-icon {
                    width: 340px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        margin-right: 20px;
                    }
                }
            }
        }

        .app-right {
            width: 680px;
            height: 600px;
        }
		.app-right-tv {
			position: relative;
			top: -100px;
		}
		.app-right-pad {
			position: relative;
			top: -40px;
		}
		.app-right-phone {
			position: relative;
			top: -40px;
		}
    }

    .tv-use {
        width: 1200px;
        height: 450px;
        background-color: #F4FBFF;
        border-radius: 40px;
        margin: 0 auto;

        .image-list {
            width: 940px;
            height: 220px;
            padding-top: 70px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 auto;
            opacity: 0;

            .image-item-a {
                width: 150px;
                height: 150px;
            }

            .image-item {
                width: 106px;
                height: 78px;
            }
        }

        .tv-list {
            width: 1120px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            opacity: 0;

            .tv-item {
                padding-top: 40px;
                width: 314px;
                text-align: center;

                .title {
                    font-size: 28px;
                    color: #2A536B;
                    line-height: 32px;
                }

                .msg {
                    font-size: 18px;
                    color: #668190;
                    line-height: 32px;
                    padding-top: 15px;
                }
            }
        }
    }

    .download-footer {
        width: 100%;
        height: 935px;
        background-image: url(../assets/images/dld-foot.png);
        background-repeat: no-repeat;
        background-position: left bottom;
        background-size: 1920px;
        position: relative;
        border-top: 1px solid #fff;
        overflow: hidden;

        .dld-left-a {
            width: 94px;
            height: 44px;
            position: absolute;
            top: 30px;
            left: -95px;
            transition: left .5s;
        }
        .dld-left-a-show {
            left: 183px;
        }

        .dld-right-a {
            width: 132px;
            height: 62px;
            position: absolute;
            top: 73px;
            right: -135px;
            transition: right .5s;
        }
        .dld-right-a-show {
            right: 82px;
        }

        .dld-right-b {
            width: 69px;
            height: 32px;
            position: absolute;
            top: 240px;
            right: -70px;
            transition: right .5s;
        }
        .dld-right-b-show {
            right: 194px;
        }

        .foot-wrap {
            width: 1200px;
            height: 477px;
            background-color: #EDF8FF;
            -webkit-border-radius: 40px;
            -moz-border-radius: 40px;
            border-radius: 40px;
            margin: 100px auto 0;
            padding: 40px;
            box-sizing: border-box;
            display: flex;
            opacity: 0;

            .foot-video-wrap {
                width: 641px;
                height: 397px;
                background-image: url(../assets/images/foot-video-wrap.png);
                background-repeat: no-repeat;
                background-position: center;
                -webkit-background-size: 641px 397px;
                background-size: 641px 397px;
                padding: 37px 0 0 37px;
                position: relative;

                .foot-video {
                    width: 566px;
                    height: 319px;
                    border-radius: 30px;
                }

                .foot-video-cover {
                    width: 566px;
                    height: 319px;
                    border-radius: 30px;
                }

                .foot-play {
                    width: 96px;
                    height: 96px;
                    position: absolute;
                    z-index: 2;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                    cursor: pointer;
                }
            }

            .foot-right {
                width: 479px;
                height: 397px;
                overflow: auto;
                padding: 15px 0 15px 60px;
                box-sizing: border-box;

                .foot-right-item {
                    width: 100%;
                    line-height: 32px;
                    font-size: 18px;
                    color: #668190;
                    padding: 15px 0;
                    cursor: pointer;
                }

                .foot-right-item-active {
                    color: #00A2FF;
                    font-size: 22px;
                }
            }

            .foot-right::-webkit-scrollbar { /*滚动条整体样式*/
                width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
                height: 1px;
            }

            .foot-right::-webkit-scrollbar-thumb { /*滚动条里面小方块*/
                border-radius: 8px;
                -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.02);
                background: #00A2FF;
            }

            .foot-right::-webkit-scrollbar-track { /*滚动条里面轨道*/
                -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.02);
                border-radius: 8px;
                background: #BEEAFF;
            }
        }
    }

    .download-footer-tv {
        margin-top: 260px;
    }

    .foot-bottom {
        height: 71px;
        width: 100%;
        background-color: #0C9CEF;
        padding-top: 15.5px;
        box-sizing: border-box;

        p {
            width: 100%;
            text-align: center;
            font-size: 12px;
            color: #ffffff;
            line-height: 20px;
			span {
				padding: 0 20px;
			}
        }
    }

    @media screen and (max-width: 1440px){
        .download-body {
            height: 818px;
            -webkit-background-size: 1440px 818px;
            background-size: 1440px 818px;
        }
        .download-body .dld-wraper {
            width: 926px;
            height: 690px
        }
        .download-body .nav-list {
            padding-top: 75px;
        }
        .download-body .nav-list .nav-item {
            font-size: 15px;
        }
        .download-body .nav-list .nav-item-active {
            font-size: 18px;
            background-size: 44px 5px;
        }
        .app-wraper .app-left {
            width: 416px;
        }
        .app-wraper .app-left .title {
            font-size: 36px;
            padding: 40px 0 29px 0;
        }
        .app-wraper .app-left .app-left-code {
            width: 225px;
            height: 225px;
        }
        .app-wraper .app-left .message {
            font-size: 14px;
        }
        .app-wraper .app-right {
            width: 510px;
            height: 510px;
        }
        .app-wraper .app-left .app-left-btn {
            width: 194px;
            height: 45px;
            line-height: 45px;
            font-size: 21px;
        }
        .tv-use {
            width: 900px;
            height: 338px;
        }
        .tv-use .image-list .image-item-a {
            width: 113px;
            height: 113px;
        }
        .tv-use .image-list .image-item {
            width: 80px;
            height: 59px;
        }
        .tv-use .image-list {
            width: 706px;
            padding-top: 50px;
            height: 170px;
        }
        .tv-use .tv-list {
            width: 840px;
        }
        .tv-use .tv-list .tv-item {
            width: 239px;
            padding-top: 30px;
        }
        .tv-use .tv-list .tv-item .title {
            font-size: 21px;
            line-height: 24px;
        }
        .tv-use .tv-list .tv-item .msg {
            font-size: 14px;
            padding-top: 10px;
            line-height: 24px;
        }
        .download-footer .foot-wrap {
            width: 900px;
            height: 358px;
            padding: 30px;
        }
        .download-footer .foot-wrap .foot-video-wrap {
            width: 480px;
            height: 297px;
            -webkit-background-size: 480px 297px;
            background-size: 480px 297px;
            padding: 27px 0 0 27px;
        }
        .download-footer .foot-wrap .foot-video-wrap .foot-video-cover {
            width: 425px;
            height: 239px;
            -webkit-border-radius: 26px;
            -moz-border-radius: 26px;
            border-radius: 26px;
        }
		.download-footer .foot-wrap .foot-video-wrap .foot-video {
			width: 425px;
			height: 239px;
			-webkit-border-radius: 26px;
			-moz-border-radius: 26px;
			border-radius: 26px;
		}
        .download-footer .foot-wrap .foot-right {
            width: 360px;
            height: 298px;
        }
        .download-footer .foot-wrap .foot-video-wrap .foot-play {
            width: 77px;
            height: 77px;
        }
        .download-footer .foot-wrap .foot-right .foot-right-item {
            font-size: 14px;
            line-height: 24px;
            padding: 6px 0;
        }
        .download-footer .foot-wrap .foot-right .foot-right-item-active {
            font-size: 17px;
        }
    }
	@media screen and (min-width: 1920px) {
		.download-body,
		.download-footer{
			background-size: 100%;
		}
	}
</style>
